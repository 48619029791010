<template>
  <div>
    <main style="margin-top: 92px;">
        <section class="mt-3">
            <div class="container container-width">
                <!-- HEADER DE CATEGORIA -->
                <div v-if="showLoader" class=" rounded-5 p-5">
                    <svg class="bd-placeholder-img card-img-top" width="100%" height="200" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                        <title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#868e96"></rect>
                    </svg>
                </div>
                <div v-if="!showLoader" class="bg-4 rounded-5 p-5">
                    <div class="row align-items-center">
                        <div class="col-xl-3">
                            <h1>{{ Categoria.Nombre }}</h1>
                            <div class="d-inline-block p-0 text-capitalize m-0">
                                <nav aria-label="breadcrumb"
                                    style="--bs-breadcrumb-divider: '>'; font-family: 'Lato', sans-serif;">
                                    <ul class="breadcrumb fs-7">
                                        <li class="breadcrumb-item">
                                            <a class="text-morado fw-bold" @click="$router.push({ name :'principal',params:{}});" style="cursor: pointer;">
                                                <i class="far fa-house me-2"></i>Home
                                            </a>
                                        </li>
                                        <template v-if="Categoria.subCatSelecionada != undefined && Categoria.subCatSelecionada != null">
                                            <li class="breadcrumb-item fw-bold text-darker">
                                                <a class="text-morado fw-bold" @click="changeCategoria(Categoria.subCatSelecionada.IdCategoria)" style="cursor: pointer;">
                                                    {{ Categoria.subCatSelecionada.Nombre }}
                                                </a>
                                            </li>
                                        </template>
                                        <template v-else>
                                            <li class="breadcrumb-item fw-bold text-darker">Productos</li>
                                        </template>
                                        <li class="breadcrumb-item active fw-bold text-darker">{{ Categoria.Nombre }}</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div class="col-xl-9 text-end d-none d-xl-block">
                            <ul class="tags">
                                <li v-for="(subcat,index) in Filtro.SubCategoriasSelect" :key="index" class="d-inline-block me-5">
                                    <a @click="removeSubCatFitro(index)" style="cursor:pointer" class="btn btn-tag">
                                        <i class="far fa-times me-2"></i>{{ subcat.Nombre }}
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>


                <!-- CONTENEDOR DE PAGINA -->
                <div class="row flex-row-reverse my-5">
                    <div class="col-12 col-lg-9 col-xl">
                        <div class="d-flex flex-wrap justify-content-between align-items-baseline mb-3">
                            <p class="ff-lato">
                                Se encontraron
                                <span class="text-morado">{{ Filtro.TotalItem }}</span>
                                articulos!
                            </p>
                            <div class="sortby d-flex align-items-center fs-7">
                                <div class="show-elements py-1 px-3 border border-1 rounded-3 d-flex me-2">
                                    <label class="d-flex align-items-center"><i
                                            class="far fa-th-large me-2"></i>Elementos:</label>
                                    <select @change="changeFiltersPage()" class="form-select border-0 fs-7" aria-label="" v-model="Filtro.Entrada">
                                        <option :value="16">16</option>
                                        <option :value="32">32</option>
                                        <option :value="64">64</option>
                                        <option :value="112">112</option>
                                    </select>
                                </div>
                                <div class="sort-by py-1 px-3 border border-1 rounded-3 d-flex">
                                    <label class="d-flex align-items-center"><i
                                            class="far fa-sort-amount-up me-2"></i>Ordernar:</label>
                                    <select @change="changeFiltersPage()" class="form-select border-0 fs-7" aria-label="" v-model="Filtro.TipoOrden">
                                        <option value="all">Todos</option>
                                        <!-- <option value="prdnew">Producto Nuevo</option> -->
                                        <option value="priceasc">Precio de menor a mayor</option>
                                        <option value="pricedesc">Precio de mayor a menor</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div class="product-grid">
                            <!-- PRODUCTOS LOADER -->
                            <div v-if="loaderProductos" class="row justify-content-center">
                                <div v-for="n in 16" :key="n" class="col-6 col-sm-6 col-md-4 col-xl-3 mb-3">
                                    <div class="card card-produc position-relative" aria-hidden="true">
                                        <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                            <title>Placeholder</title>
                                            <rect width="100%" height="100%" fill="#868e96"></rect>
                                        </svg>
                                        <div class="card-body">
                                            <h5 class="card-title placeholder-glow">
                                                <span class="placeholder col-6"></span>
                                            </h5>
                                            <p class="card-text placeholder-glow">
                                                <span class="placeholder col-7"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-6"></span>
                                                <span class="placeholder col-8"></span>
                                            </p>
                                            <p class="placeholder-glow">
                                                <span class="placeholder col-4"></span>
                                            </p>
                                            
                                        </div>
                                    </div>

                                    
                                </div>
                                
                            </div>

                            <!-- PRODUCTOS LISTADO -->
                            <div v-if="!loaderProductos" class="row justify-content-center">
                                <!--<pre> {{ listProductos }} </pre> -->
                                <div v-for="(item, indx) in listProductos" :key="'prod_'+indx" class="col-6 col-sm-6 col-md-4 col-xl-3 mb-3">
                                    <div class="card card-produc position-relative" :id="'Producto_'+item.IdProducto">
                                        <div v-if="parseInt(item.aplicaRebaja) == 1" class="tag px-2 py-1 bg-danger fw-bold">OFERTA</div>
                                        <!-- <div class="tag px-2 py-1 bg-info fw-bold">NUEVO</div> -->
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <a @click="viewDetalle(item.categoriaprincipal.IdCategoria,item.categoriaprincipal.Nombre,item)" style="cursor:pointer">
                                                        <figure class="m-0 card-img">
                                                            <img :src="get_ImagePreview(item.imagenescolor,0)" alt="Producto" :id="'imgPrSug_'+item.IdProducto"
                                                                class="img-fluid show-img c1" />
                                                            
                                                            <!-- <template v-if="item.imagenprincipal != undefined && item.imagenprincipal != null">
                                                                <img :src="RutaProducto+item.IdProducto+'/'+item.imagenprincipal.Imagen" alt="Producto" :id="'imgPrSug_'+item.IdProducto"
                                                                class="img-fluid show-img c1" />
                                                            </template>
                                                            <template v-else>
                                                                <img :src="RutaProducto+'/noimagen.jpg'" alt="Producto" :id="'imgPrSug_'+item.IdProducto"
                                                                class="img-fluid show-img c1" />
                                                            </template> -->
                                                            
                                                        </figure>
                                                    </a>
                                                    <p class="mb-2">
                                                        <span v-if="item.categoriaprincipal != undefined && item.categoriaprincipal != null" class="text-body-tertiary fs-7">{{ item.categoriaprincipal.Nombre }}</span>
                                                    </p>
                                                </div>
                                                <div class="col-12">
                                                    <div class="colors">
                                                        <div v-for="(color,indx) in item.imagenescolor" :key="indx" 
                                                            @mouseover="cambiarImgColor(color)"
                                                            @click="cambiarImgColor(color)"
                                                            v-b-tooltip.hover.Top :title="color.Nombre"
                                                            class="color-available" :style=" color.Color == '#ffffff' ? 'border-color:#000; border-width: 1px; background-color:'+color.Color+';' : 'background-color:'+color.Color+';'">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <h2 class="fs-6 mb-1">
                                                        <a @click="viewDetalle(item.categoriaprincipal.IdCategoria,item.categoriaprincipal.Nombre,item)" style="cursor:pointer" class="text-darker hover-purple-text">
                                                            {{ item.Nombre }}
                                                        </a>
                                                    </h2>
                                                </div>
                                                
                                                <div class="col-12">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <span class="fw-bolder w-auto fs-5">
                                                                {{ $formatNumber(item.PrecioFinal,'$') }}
                                                            </span>
                                                            <span v-if="parseInt(item.aplicaRebaja) == 1" class="text-decoration-line-through fs-6">{{ $formatNumber(item.Precio,'$')}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row">
                                <CPagina :Filtro="Filtro" @Pagina="listaProductos(50)"></CPagina>
                            </div>
                        </div>
                    </div>

                    <!-- FILTROS LATERALES LOADER -->
                    <div v-if="showLoader" class="col-12 col-lg-3 col-lg-1-5 sidebar"> 
                        <div class="sidebar-content">
                            <div class="row justify-content-center">

                                <div class="col-12 col-md-8 col-lg-12">
                                    <div class="rounded-3 p-3 border border-1 light-boxshadow mb-3">
                                        <p class="placeholder-glow">
                                            <span class="placeholder placeholder-lg col-12"></span>
                                        </p>
                                        <ul class="list-unstyled">
                                            <li v-for="n in 4 " :key="n" class="d-flex justify-content-between align-items-center p-2 border border-1 rounded my-3">
                                                <div class="col-2">
                                                    
                                                        <svg class="img-fluid filter-cat-img" width="100%" height="25" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                                            <title>Placeholder</title>
                                                            <rect width="100%" height="100%" fill="#868e96"></rect>
                                                        </svg>
                                                        
                                                </div>
                                                <div class="col-10">
                                                    <p class="placeholder-glow mt-2">
                                                            <span class="placeholder col-12"></span>
                                                        </p>
                                                </div>
                                                
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-12 col-md-8 col-lg-12">
                                    <div class="rounded-3 p-3 border border-1 light-boxshadow mb-3">
                                        <p class="placeholder-glow">
                                            <span class="placeholder placeholder-lg col-12"></span>
                                        </p>
                                        <ul class="list-unstyled">
                                            <li v-for="n in 4 " :key="n" class="d-flex justify-content-between align-items-center p-2 border border-1 rounded my-3">
                                                <div class="col-2">
                                                    
                                                        <svg class="img-fluid filter-cat-img" width="100%" height="25" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                                            <title>Placeholder</title>
                                                            <rect width="100%" height="100%" fill="#868e96"></rect>
                                                        </svg>
                                                        
                                                </div>
                                                <div class="col-10">
                                                    <p class="placeholder-glow mt-2">
                                                            <span class="placeholder col-12"></span>
                                                        </p>
                                                </div>
                                                
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-12 col-md-8 col-lg-12">
                                    <div class="rounded-3 p-3 border border-1 light-boxshadow mb-3">
                                        <h4 class="title-01"><span class="placeholder placeholder-lg col-12"></span></h4>
                                        <div v-for="n in 3" :key="'pnv'+n" class="row new-product justify-content-start align-items-center my-3">
                                            <div class="col-5">
                                                <svg class="img-fluid rounded-3" width="100%" height="94" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                                    <title>Placeholder</title>
                                                    <rect width="100%" height="100%" fill="#868e96"></rect>
                                                </svg>
                                            </div>
                                            <div class="col-7">
                                                    <h5 class="text-morado fs-6 text-truncate placeholder-glow">
                                                        <span class="placeholder col-10"></span>
                                                    </h5>
                                                
                                                <div class="price">
                                                    <p class="my-1 fs-6 align-middle placeholder-glow">
                                                        <span class="placeholder col-8"></span>
                                                    </p>
                                                </div>
                                                <p class="my-1 fs-9 placeholder-glow">
                                                    <span class="placeholder col-6"></span>
                                                </p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>  
                    </div> 

                    <!-- FILTROS LATERALES -->
                    <div v-if="!showLoader" class="col-12 col-lg-3 col-lg-1-5 sidebar">
                        <div class="sidebar-content">
                            <div class="row justify-content-center">

                            <!-- FILTOS SUB-CATEGORIAS -->
                                <div class="col-12 col-md-8 col-lg-12">
                                    <div class="rounded-3 p-3 border border-1 light-boxshadow mb-3">
                                        <h4 class="title-01">Categorias</h4>
                                        <ul class="list-unstyled">
                                            <li
                                                class="d-flex justify-content-between align-items-center p-2 border border-1 rounded my-3 hover-category">
                                                <a class="d-flex align-items-center" style="cursor:pointer" @click="limpiaFiltroSubCats()">
                                                    <img class="img-fluid filter-cat-img"
                                                        :src="rutaIconos+'ofertas.svg'" alt="" />
                                                    <span class="category-name ms-2 fs-7 ff-lato">Todos</span>
                                                </a>
                                                <!-- <span class="category-qty ff-lato">{{ Filtro.TotalItem }}</span> -->
                                            </li>

                                            <li v-for="(subcat,index) in Categoria.subcategorias " :key="index" class="d-flex justify-content-between align-items-center p-2 border border-1 rounded my-3 hover-category">
                                                <a class="d-flex align-items-center" style="cursor:pointer" @click="addSubCatFiltro(subcat)">
                                                    <img class="img-fluid filter-cat-img"
                                                        :src="rutaIconos+subcat.Icono" />
                                                    <span class="category-name ms-2 fs-7 ff-lato">{{ subcat.Nombre }}</span>
                                                </a>
                                                <!--<span class="category-qty ff-lato">10</span> -->
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>

                            <!-- FILTOS TALLA, COLOR, RANGO PRECIO -->
                                <div class="col-12 col-md-8 col-lg-12">
                                    <div class="rounded-3 p-3 border border-1 light-boxshadow mb-3">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="filter-price my-4">
                                                    <label for="pricerange" hidden>Precio</label>
                                                    <input type="range" class="form-range my-3" id="pricerange" v-b-tooltip.hover.Top
                                                    :min="Filtro.RangoPrecio.min" :max="Filtro.RangoPrecio.max" step="1" v-model="Filtro.RangoPrecio.selected">

                                                    <div class="d-flex justify-content-between fs-7">
                                                        <div class="text-gray">
                                                            Desde:
                                                            <span class="text-morado">${{ Filtro.RangoPrecio.min }}</span>
                                                        </div>
                                                        <div class="text-gray">
                                                            Hasta:
                                                            <span  class="text-morado">${{ $formatOnlyNumeric(Filtro.RangoPrecio.selected,false) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12"></div>
                                            <div class="col-12"></div>
                                            <div class="col-12"></div>
                                            <div class="col-12"></div>
                                        </div>
                                        <h4 class="title-01">Filtrar resultados</h4>

                                        <div class="filter-brand my-4">
                                            <h5 class="ff-lato fs-7 fw-bolder text-gray">Tallas</h5>
                                            <div v-for="(talla, indx) in listFiltroTallas" :key="'ftalla_'+indx" class="form-check">
                                                <input class="form-check-input" type="checkbox" :value="talla.IdTalla" :id="'colf_'+talla.IdTalla" v-model="Filtro.TallasSelect"/>
                                                <label class="form-check-label fs-7"> {{ talla.Nombre }} </label>
                                            </div>

                                            <div v-if="bndMoreTalla" class="form-check">
                                                <label @click="verTodosTallas()" class="form-check-label fs-7 text-morado" for="Ver_todos" style="cursor: pointer;">
                                                    Ver mas opciones... <i class="fa fa-solid fa-caret-down"></i>
                                                </label>
                                            </div>
                                            <div v-if="bndLessTalla" class="form-check">
                                                <label @click="verMenosTallas()" class="form-check-label fs-7 text-morado" for="Ver_menos" style="cursor: pointer;">
                                                    Ver menos opciones... <i class="fa fa-solid fa-caret-up"></i>
                                                </label>
                                            </div>
                                           
                                        </div>

                                        <div class="filter-color my-4">
                                            <h5 class="ff-lato fs-7 fw-bolder text-gray">Color</h5>
                                            <div v-for="(color, indx) in listFiltroColores" :key="'fcolor_'+indx"  class="form-check">
                                                <input class="form-check-input" type="checkbox" :value="color.IdColor" :id="'colf_'+color.IdColor" v-model="Filtro.ColoresSelect"/>
                                                <label class="form-check-label fs-7" > {{ color.Nombre }} </label>
                                            </div>

                                            <div v-if="bndMoreColor" class="form-check">
                                                <label @click="verTodosColores()" class="form-check-label fs-7 text-morado" for="Ver_todos" style="cursor: pointer;">
                                                    Ver mas opciones... <i class="fa fa-solid fa-caret-down"></i>
                                                </label>
                                            </div>
                                            <div v-if="bndLessColor" class="form-check">
                                                <label @click="verMenosColores()" class="form-check-label fs-7 text-morado" for="Ver_menos" style="cursor: pointer;">
                                                    Ver menos opciones... <i class="fa fa-solid fa-caret-up"></i>
                                                </label>
                                            </div>
                                            
                                        </div>

                                        

                                        <button class="btn btn-verde my-4 py-2 px-4 fs-8 me-3" @click="listaProductos(51)">
                                            <i class="far fa-filter me-2"></i>Filtrar
                                        </button>
                                        

                                        <button class="btn btn-primary my-4 py-2 px-4 fs-8" @click="changeFiltersPage()">
                                            <i class="far fa-filter me-2"></i>Borrar Filtros
                                        </button>
                                    </div>
                                </div>

                            <!-- ARTICULOS NOVEDOSOS -->
                                <div class="col-12 col-md-8 col-lg-12">
                                    <ProdRecomenVerticalVue :IdCategoria="0" :pProducto="pProdRecom" />
                                </div>
                            <!-- BANNER PROMOCIONES -->
                                <div class="col-12 col-md-8 col-lg-12">
                                    <FlyerPromVerticalVue/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <ProductosOfertasVue v-if="!showLoader" :pTituloSeccion="'Ofertas'"/>
        

        <section v-if="!showLoader" class="container container-width mb-4">
            <div class="position-relative newsletter bg-5 p-3 p-sm-5">
                <div class="row">
                    <div class="col-12 col-lg-9 col-xl-6">
                            <div class="row mb-4">
                                <div class="col-12">
                                    <p class="fs-1 fw-bolder text-light">
                                        ¡No esperes mas <br>y se parte de la familia con Zumba Wear!
                                    </p>
                                </div>
                                <div class="col-12">
                                    <!--<p class="fs-6 text-body">
                                        Suscribte a nuestro
                                        <span class="text-morado">Newsletter</span>.
                                    </p>-->
                                </div>
                            </div>
                            <!--<form class="form" action="">
                                <div class="bg-white d-flex justify-content-between rounded-5 w-auto">
                                    <div class="d-flex">
                                        <i class="far fa-paper-plane text-body-tertiary my-auto ps-4"></i>
                                        <input type="email" class="shadow-none form-control border-0 h-100 rounded-5"
                                            placeholder="ejemplo@email.com" />
                                    </div>
                                    <div class="text-end">
                                        <button class="btn fw-bold btn-primary rounded-5 px-3 py-3 fs-6">
                                            Suscribirse
                                        </button>
                                    </div>
                                </div>
                            </form> -->
                        </div>
                </div>
            </div>
        </section>

        <!--<section>
            <div class="container container-width my-5">
                <div class="row">
                    <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 col-xl-2 mb-3">
                        <div class="d-flex align-items-center p-2 rounded-2 feature hoverup-feature">
                            <div class="banner-img px-2">
                                <img class="img-fluid" src="./images/icons/icon-1.svg" alt="">
                            </div>
                            <div class="banner-text">
                                <h3 class="fs-5">Descuentos</h3>
                                <p class="text-gray fs-6 ff-lato">Lorem ipsum dolor sit.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 col-xl-2 mb-3">
                        <div class="d-flex align-items-center p-2 rounded-2 feature hoverup-feature">
                            <div class="banner-img px-2">
                                <img class="img-fluid" src="./images/icons/icon-2.svg" alt="">
                            </div>
                            <div class="banner-text">
                                <h3 class="fs-5">Compra Segura</h3>
                                <p class="text-gray fs-6 ff-lato">Lorem ipsum dolor sit.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 col-xl-2 mb-3">
                        <div class="d-flex align-items-center p-2 rounded-2 feature hoverup-feature">
                            <div class="banner-img px-2">
                                <img class="img-fluid" src="./images/icons/icon-3.svg" alt="">
                            </div>
                            <div class="banner-text">
                                <h3 class="fs-5">Factura tus compras</h3>
                                <p class="text-gray fs-6 ff-lato">Lorem ipsum dolor sit.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 col-xl-2 mb-3">
                        <div class="d-flex align-items-center p-2 rounded-2 feature hoverup-feature">
                            <div class="banner-img px-2">
                                <img class="img-fluid" src="./images/icons/icon-4.svg" alt="">
                            </div>
                            <div class="banner-text">
                                <h3 class="fs-5">Productos Originales</h3>
                                <p class="text-gray fs-6 ff-lato">Lorem ipsum dolor sit.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 col-xl-2 mb-3">
                        <div class="d-flex align-items-center p-2 rounded-2 feature hoverup-feature">
                            <div class="banner-img px-2">
                                <img class="img-fluid" src="./images/icons/icon-5.svg" alt="">
                            </div>
                            <div class="banner-text">
                                <h3 class="fs-5">Envíos Seguros</h3>
                                <p class="text-gray fs-6 ff-lato">Lorem ipsum dolor sit.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> Perks-->
    </main>
  </div>
</template>

<script>
import CPagina from "@/components/CPagina.vue";
import ProductosOfertasVue from './ProductosOfertas.vue';
import FlyerPromVerticalVue from './FlyerPromVertical.vue';
import ProdRecomenVerticalVue from './ProdRecomenVertical.vue';

export default {
    name: 'ListadoProductos',
    props:["pIdCategoria","pIsTop","pTextBusqueda"],
    components:{
        CPagina,
        ProductosOfertasVue,
        FlyerPromVerticalVue,
        ProdRecomenVerticalVue
    },

    data() {
        return {
            showLoader: true,
            loaderProductos: true,
            isReady: false,
            
            Categoria: {
                IdCategoria: 0,
                Nombre: '',
                Imagen: '',
                Icono: '',
                subCatSelecionada:{},
                subcategorias: []
            },
            rutaCaratula: '',
            rutaIconos: '',

            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 16,
                TotalItem: 0,
                Placeholder: 'Buscar..',
                TipoOrden: 'all',

                SubCategoriasSelect:[],
                RangoPrecio:{
                    min: 100,
                    max: 5000,
                    selected:5000
                },
                ColoresSelect:[],
                TallasSelect:[],
                IsTop: 0,
            },

            listProductos: [],
            RutaProducto: '',

            listFiltroColores:[],
            listFilColorTotal:[],
            bndMoreColor: false,
            bndLessColor: false,

            listFiltroTallas:[],
            listFilTallaTotal:[],
            bndMoreTalla: false,
            bndLessTalla: false,

            pProdRecom:{
                IdProducto: 0
            }
            
            
        };
    },

    created() {
        this.bus.$off('CambiarCategoria2');
        this.limpiaFiltros();
        this.limpiaCategoria();
    },
    mounted() {
        this.bus.$emit('cambiaLogoNegro');

        if(sessionStorage.getItem('currentPage') && sessionStorage.getItem('currentPage') !== undefined) {
            this.Filtro.Pagina = parseInt(sessionStorage.getItem('currentPage'));
        }
        
        

        if(this.pIdCategoria != undefined && this.pIdCategoria != 0){
            this.Categoria.IdCategoria = this.pIdCategoria;
            sessionStorage.setItem("IdCategoria",this.pIdCategoria);

        }else if(sessionStorage.getItem("IdCategoria") != undefined && sessionStorage.getItem("IdCategoria") != null && parseInt(sessionStorage.getItem("IdCategoria")) > 0){
            this.Categoria.IdCategoria = sessionStorage.getItem("IdCategoria");

        }else {
            this.Categoria.IdCategoria = 0;
        }

        if(this.pIsTop != undefined && this.pIsTop != null && this.pIsTop != 0){
            this.Filtro.IsTop = this.pIsTop;
            sessionStorage.setItem("TopProd",this.Filtro.IsTop);

        }else if(sessionStorage.getItem("TopProd") != undefined ) {
            this.Filtro.IsTop = sessionStorage.getItem("TopProd");
        }

        if(this.pTextBusqueda != undefined && this.pTextBusqueda != null && this.pTextBusqueda != ''){
            this.Filtro.Nombre = this.pTextBusqueda;
        }else {
            this.pTextBusqueda = '';
            this.Filtro.Nombre = '';
        }



        /*
        * @Emmit
        * Se activa cuando se accede desde el menu general de la pagina, cambiando de categoria
        */
        this.bus.$on('CambiarCategoria2',(params) => {

            sessionStorage.setItem('PositionProduct','');
            sessionStorage.setItem('currentPage','1');

            this.Filtro.IsTop = 0;
            sessionStorage.setItem("TopProd",0);
            
            this.limpiaFiltros();
            this.limpiaCategoria();
           
            if(params.pIdCategoria != undefined && params.pIdCategoria != 0)  // Si recibe parametros correctos se toman
            {
                this.Categoria.IdCategoria = params.pIdCategoria;
                sessionStorage.setItem("IdCategoria",params.pIdCategoria);

            }else if(sessionStorage.getItem("IdCategoria") != undefined) // Si no, Intenta buscando el ultimo Id en la sessionStorage
            { 
                this.Categoria.IdCategoria = sessionStorage.getItem("IdCategoria");

            }else{
                this.Categoria.IdCategoria = 0;
            } /*else // si no, el ultimo recurso es tomar el primer elemento del arraego de categorias del sessionStorage
            {
                const cats = JSON.parse( sessionStorage.getItem("categorias") );
                this.Categoria.IdCategoria = cats[0].IdCategoria; 
                sessionStorage.setItem("IdCategoria",this.Categoria.IdCategoria);
            }*/

            
            if(params.pTextBusqueda != undefined && params.pTextBusqueda != null && params.pTextBusqueda != ''){
                this.Filtro.Nombre = params.pTextBusqueda;
            }
            
            this.recoveryCategoria(2);
        });


        this.recoveryCategoria(1);
        
    },

    methods: {
        async recoveryCategoria(op = 9) {
            this.showLoader     = true;
            this.loaderProductos = true;
            if(this.Categoria.IdCategoria > 0) {
                await this.$http.get('categoriasextended/'+this.Categoria.IdCategoria)
                .then( (res) => {
                    //console.log(res.data.data);
                    this.Categoria 	    = res.data.data;
                    this.rutaCaratula   = res.data.rutaCaratula;
                    this.rutaIconos     = res.data.rutaIcono;

                }).catch( err => {
                    console.log(err);
                }).finally(() => {
                    this.showLoader     = false;
                    this.listaProductos(op);
                });

            }else {
                this.showLoader     = false;
                this.listaProductos(op);
            }
            
        },

        
        async listaProductos(acc = 0) {
            console.log('origen: '+acc);
            this.loaderProductos = true;
            this.isReady        = false;
            this.listProductos  = [];
            /*let loader = this.$loading.show({
                // Optional parameters
                loader:'dots',
                container:this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
            });*/

            let idSubCatsSelected = [];
            this.Filtro.SubCategoriasSelect.forEach((elemento) => idSubCatsSelected.push(elemento.id) );
                
            let newRequest = {
                txtBusqueda: this.Filtro.Nombre,
                Entrada: this.Filtro.Entrada,
                Pag: this.Filtro.Pagina,

                IdCategoria: this.Categoria.IdCategoria,
                SubCategorias: JSON.stringify(idSubCatsSelected),
                Colores: JSON.stringify(this.Filtro.ColoresSelect),
                Tallas: JSON.stringify(this.Filtro.TallasSelect),
                maxPrice: this.Filtro.RangoPrecio.selected,
                TipoOrden: this.Filtro.TipoOrden,
                IsTop:this.Filtro.IsTop,
                needCounters: 1,
            }

            window.scrollTo(0,0);
            sessionStorage.setItem('currentPage',this.Filtro.Pagina);

            await this.$http.post('productos', newRequest)
            .then( (res) => {
                //console.log(res.data);
                this.listProductos 			= res.data.data.data;
                this.RutaProducto 			= res.data.rutaFile;

                //COLORES
                this.listFilColorTotal      = res.data.dataCondensada.colores;

                if( this.listFilColorTotal.length > 6 ) {
                    this.listFiltroColores      = this.listFilColorTotal.slice(0, 6);
                    this.bndMoreColor           = true;

                }else {
                    this.listFiltroColores      = this.listFilColorTotal;
                    this.bndMoreColor           = false;
                }
                
                //TALLAS
                this.listFilTallaTotal      = res.data.dataCondensada.tallas;

                if( this.listFilTallaTotal.length > 6 ) {
                    this.listFiltroTallas      = this.listFilTallaTotal.slice(0, 6);
                    this.bndMoreTalla           = true;

                }else {
                    this.listFiltroTallas      = this.listFilTallaTotal;
                    this.bndMoreTalla           = false;
                }
                

                this.Filtro.Pagina			= res.data.data.current_page;
                this.Filtro.TotalItem 	    = res.data.data.total;
                this.Filtro.Entrada	        = res.data.data.per_page;

                this.isReady = true;

            }).finally( () => {
                //loader.hide();
                this.loaderProductos     = false;

                // POSICIONA EL SCROLL SI EXISTE EL DIV DEL PRODUCTO
                if(sessionStorage.getItem('PositionProduct') !== undefined && sessionStorage.getItem('PositionProduct') !== null){
                    const div = document.getElementById(sessionStorage.getItem('PositionProduct'));
                    if(div !== null && div !== undefined) {
                        div.scrollIntoView({behavior: "smooth"});
                    }
                                   
                }
            });

        }, 

        /**
         * Agrega subcategorias del filtro de para poder listar por ellas
         * 
         */
        addSubCatFiltro(item) {

            //BUSCAMOS SI PREVIAMENTE NO SE HA AGREGADO ESA SUBCATEGORIA AL FILTRO
            let verifica  = this.Filtro.SubCategoriasSelect.find((elemento) => elemento.id == item.IdCategoria);

            if(verifica == undefined) {
                this.Filtro.SubCategoriasSelect.push({
                    id:item.IdCategoria,
                    Nombre: item.Nombre
                })
            }
            this.listaProductos(3);
        },
        
        /**
         * Retira las subcategorias del filtro de subcategorias
         * 
         */
        removeSubCatFitro(index) {
            this.Filtro.SubCategoriasSelect.splice(index, 1);
            this.listaProductos(4);
        },

        /**
         * Se Activa al usar una funcion de paginado o de ordenamiento.
         * 
         */
        changeFiltersPage(){
            this.Filtro.Pagina          = 1;
            this.Filtro.ColoresSelect   = [];
            this.Filtro.TallasSelect    = [];
            this.Filtro.RangoPrecio = {
                min: 100,
                max: 5000,
                selected:5000
            },
            this.bndMoreColor           = false;
            this.bndLessColor           = false;
            this.bndLessTalla           = false;
            this.bndMoreTalla           = false;
            this.listaProductos(5);
        },

        /**
         * Retira todas las subcategorias del filtro
         * 
         */
        limpiaFiltroSubCats(){
            this.Filtro.Pagina = 1;
            this.Filtro.SubCategoriasSelect = [];
            this.listaProductos(6);
        },

        /**
         * Retira todos los parametros de los filtros
         * Restablece los parametros iniciales de filtrado
         */
        limpiaFiltros() {
            this.Filtro = {
                Nombre: '',
                Pagina: 1,
                Entrada: 16,
                TotalItem: 0,
                Placeholder: 'Buscar..',
                SubCategoriasSelect:[],
                RangoPrecio:{
                    min: 100,
                    max: 5000,
                    selected:5000
                },
                ColoresSelect:[],
                TallasSelect:[],
                TipoOrden: 'all'
            };
            this.bndMoreColor = false;
            this.bndLessColor = false;

            this.bndLessTalla = false;
            this.bndMoreTalla = false;
            
        },

        /**
         * Restablece los parametros iniciales del Objeto
         * Categoria y coloca valores por defecto en caso de falla
         */
        limpiaCategoria() {
            this.Categoria = {
                IdCategoria: 0,
                Nombre: 'Descubre mas de Zumba Wear',
                Imagen: 'generalCaratulaCats.png',
                Icono: '',
                subcategorias: []
            };
        },

        get_ImagePreview(array,index) {
            var nameimagen="";
            if(array != undefined && array != null & array.length > 0) {
                if(array[index]) {
                    nameimagen=array[index].Imagen
                }
                return this.RutaProducto+array[index].IdProducto+'/'+nameimagen;
            }else {
                return this.RutaProducto+'noimagen.jpg';
            }
            
            
        },


        cambiarImgColor(arrayImgs) {
            var imagenname = "";
            if(arrayImgs.Imagen !== undefined) {
                imagenname=arrayImgs.Imagen;
            }
            document.getElementById("imgPrSug_"+arrayImgs.IdProducto).src = this.RutaProducto+arrayImgs.IdProducto+'/'+imagenname;
        },

        viewDetalle(idcategoria,nomcategoria,producto) {

            sessionStorage.setItem('PositionProduct','Producto_'+producto.IdProducto);

            this.$router.push({name:'viewproducto',
                query:{
                    'cat':idcategoria,
                    'prod':producto.IdProducto,
                    'feat':0,
                    'nomcat':nomcategoria.replace(/\s+/g, '-'),
                    'nomp':producto.Nombre.replace(/\s+/g, '-')
                }
            });
            //this.bus.$emit('cambiaLogoNegro');
        },

        changeCategoria(idCategoria){
            sessionStorage.setItem('PositionProduct','');
            sessionStorage.setItem('currentPage','1');

            this.Filtro.IsTop = 0;
            sessionStorage.setItem("TopProd",0);
            
            this.limpiaFiltros();
            this.limpiaCategoria();

            this.Categoria.IdCategoria = idCategoria;
            sessionStorage.setItem("IdCategoria",idCategoria);

            this.recoveryCategoria();
            this.listaProductos(7);
        },

        verTodosColores() {
            this.listFiltroColores = [];
            this.listFiltroColores      = this.listFilColorTotal;
            this.bndMoreColor           = false;
            this.bndLessColor           = true;
        },

        verMenosColores() {
            this.listFiltroColores = [];
            this.listFiltroColores      = this.listFilColorTotal.slice(0, 6);

            if(this.Filtro.ColoresSelect.length > 0) {
                this.Filtro.ColoresSelect.forEach(element => {
                    let serch = this.listFiltroColores.filter(item => item.IdColor == element );

                    if(serch.length == 0) {
                        let faltante = this.listFilColorTotal.filter(item => item.IdColor == element);
                        this.listFiltroColores.push(faltante[0]);
                        
                    }
                });
            }
            
            this.bndLessColor   = false;
            this.bndMoreColor   = true;
        },

        verTodosTallas() {
            this.listFiltroTallas = [];
            this.listFiltroTallas      = this.listFilTallaTotal;
            this.bndMoreTalla           = false;
            this.bndLessTalla           = true;
        },

        verMenosTallas() {
            
            this.listFiltroTallas = [];
            this.listFiltroTallas      = this.listFilTallaTotal.slice(0, 6);

            if(this.Filtro.TallasSelect.length > 0) {

                this.Filtro.TallasSelect.forEach(element => {
                    let serch = this.listFiltroTallas.filter(item => item.IdTalla == element );

                    if(serch.length == 0) {
                        let faltante = this.listFilTallaTotal.filter(item => item.IdTalla == element);
                        this.listFiltroTallas.push(faltante[0]);
                        
                    }
                }); 
            }

            
            this.bndLessTalla   = false;
            this.bndMoreTalla   = true;
        },

    },
};
</script>

<style lang="scss" scoped>

</style>

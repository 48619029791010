<template>
    <div>
        <footer class="text-center text-md-start">
            <!-- Widgets -->
            <div class="footer-widgets">
                <div class="container">
                    <div class="row">
                        <!-- Footer logo -->
                        <div class="col-12 col-md-6 col-lg-3">
                            <div class="widget">
                                <p class="footer-logo">
                                    <img src="@/assets/images/logo_zumba.png" alt="Zumbashop" data-rjs="2" width="255px" />
                                </p>
                                <p class="text-uppercase">Zumbashop Mx</p>
                                <p>C. 25 esquina con 16-B Chichi Suárez, C.P 97306 97306 Mérida, Yucatán.</p>
                                <p>claudia.castillo@zumbashop.mx</p>
                                <p>9994 429 749</p>

                                <!-- Social links -->
                                <div class="footer-social text-center text-white">
                                    <a title="x-twitter"    href="#" target="_blank"><font-awesome-icon :icon="['fab', 'x-twitter']" style="color: #ffffff"/></a>
                                    <a title="Facebook"     href="https://www.facebook.com/zumbashop.mx/" target="_blank"><i class="fab fa-facebook-f fa-fw"></i></a>
                                    <a title="Instagram"    href="https://www.instagram.com/zumbashopmx/" target="_blank"><i class="fab fa-instagram"></i></a>
                                    <a title="Whatsapp"     href="https://wa.me/529994429749" target="_blank"><i class="fab fa-whatsapp"></i></a>
                                    <a title="TikTok"       href="https://www.tiktok.com/@zumbashopmx?is_from_webapp=1&sender_device=pc" target="_blank"><font-awesome-icon :icon="['fab', 'tiktok']" style="color: #ffffff" /></a>
                                </div>
                            </div>
                        </div>

                        <!-- Useful links -->
                        <div class="col-12 col-md-6 col-lg-2 offset-lg-1 res-margin">
                            <div class="widget">
                                <h6>Enlaces útiles</h6>

                                <ul class="footer-menu ">
                                    <li> <a style="cursor:pointer" @click="nosotros()">Nosotros</a> </li>
                                    <li> <a style="cursor:pointer" @click="contacto()">Contacto</a> </li>
                                    <li> <a style="cursor:pointer" @click="politicas()">Políticas de privacidad</a>  </li>
                                    <li> <a style="cursor:pointer" @click="terminos()">Términos y Condiciones</a>  </li>
                                    <li> <a style="cursor:pointer" @click="garantias()">Garantías</a>  </li>
                                </ul>
                            </div>
                        </div>

                        <!-- Product help -->
                        <div class="col-12 col-md-6 col-lg-3 res-margin">
                            <div class="widget">
                                <h6>Categorias</h6>

                                <ul class="footer-menu">
                                    <li><a style="cursor:pointer" @click="viewListProducto(2)">Hombres</a></li>
                                    <li><a style="cursor:pointer" @click="viewListProducto(4)">Mujeres</a></li>
                                    <li><a style="cursor:pointer" @click="viewListProducto(5)">Unisex</a></li>
                                    <li><a style="cursor:pointer" @click="viewWall()">Wearwall</a></li>
                                    <!--li><a href="#">Todas</a></li>-->
                                </ul>
                            </div>
                        </div>

                        <!-- Download -->
                        <div class="col-12 col-md-6 col-lg-3">
                            <div class="widget text-center" style="justify-content: center;">
                                <h6>Pagos Seguros </h6> 
                                <img width="50%" src="https://images.openpay.mx/logo_new.png">
                                <br>
                                <br>
                                <img class="mx-2" width="25%" src="@/assets/images/visa.png" alt="" style="visibility: visible;">
                                <img class="me-2" width="25%" src="@/assets/images/mastercard.png" alt="" style="visibility: visible;">
                                <img width="25%" src="@/assets/images/american-express.png" alt="" style="visibility: visible;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Copyright -->
            <div class="footer-copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <!-- Text -->
                            <p class="copyright text-center">
                                Copyright © {{ anio }}
                                <a href="https://www.facebook.com/zumbashop.mx/" target="_blank">Zumbashopmx</a>.
                                Todos los derechos reservados. By
                                <a href="https://lugarcreativo.mx/">Lugar Creativo</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

export default {
    name: 'FooterPage',
    components: {
    
    },
    data() {
        return {
            anio: new Date().getFullYear(),
        }
    },
    methods:{
        viewListProducto(idCategoria){
            if(this.$route.name == "productos"){

                let parametros = {
                        pIdCategoria: idCategoria, 
                        pTextBusqueda: '',
                        pIsTop: 0,
                    };

                this.bus.$emit('CambiarCategoria2',parametros);

            }else{

                this.$router.push({ name :'productos',params:{
                        pIdCategoria: idCategoria, 
                        pTextBusqueda: '',
                        pIsTop: 0, 
                        
                    }
                });
            }

        },

        viewWall() {
            this.$router.push({name: 'wearwall', params:{}});
        },

        nosotros() {
            this.$router.push({name: 'nosotros', params:{}});
        },
        politicas() {
            this.$router.push({name: 'politicas', params:{}});
        },
        terminos() {
            this.$router.push({name: 'terminos', params:{}});
        },
        garantias() {
            this.$router.push({name: 'garantias', params:{}});
        },
        contacto() {
            this.$router.push({name: 'contacto', params:{}});
        },
        

    }
}
</script>
